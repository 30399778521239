<template>
  <div id="app" class="dark">
    <video-intro
      v-if="showVideo"
      @video-ended="onVideoEnded"
    ></video-intro>
    <router-view v-else />
  </div>
</template>

<script>
import VideoIntro from "./components/VideoIntro.vue";

export default {
  name: "App",
  components: {
    VideoIntro,
  },
  data() {
    return {
      showVideo: localStorage.getItem('videoWatched') !== 'true',  // Verifica se o vídeo já foi assistido
    };
  },
  methods: {
    onVideoEnded() {
      this.showVideo = false;  // Esconde o vídeo
      localStorage.setItem('videoWatched', 'true');  // Marca como assistido no localStorage
    }
  }
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

/* Estilos adicionais para o componente de vídeo */
.video-intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: black;
}

.video-intro video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
