<template>
  <div>
    <button @click="toggleScreenWakeLock" v-if="wakeLockSupported">
      {{ wakeLockActive ? "Desativar" : "Manter tela ligada" }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wakeLockSupported:
        "wakeLock" in navigator && "request" in navigator.wakeLock,
      wakeLockActive: false,
      wakeLock: null,
    };
  },
  methods: {
    async toggleScreenWakeLock() {
      if (!this.wakeLockActive) {
        try {
          this.wakeLock = await navigator.wakeLock.request("screen");
          this.wakeLockActive = true;
          this.wakeLock.addEventListener("release", () => {
            this.wakeLockActive = false;
          });
        } catch (err) {
          console.error(
            `Falha ao solicitar WakeLock: ${err.name}, ${err.message}`
          );
        }
      } else {
        this.wakeLock.release();
        this.wakeLock = null;
        this.wakeLockActive = false;
      }
    },
  },
};
</script>
