<template>
  <div class="dark">
    <NavbarComponent v-show="showNavbar" />
    <FloatingLock />
    <div class="content-container">
      <div class="background-image" v-show="!showIframe"></div>
     <div id="card" class="flex items-center justify-center min-h-screen px-4" v-if="!showIframe">

        <div class="card-container centered">
          <form style="max-width: 400px" class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl" @submit.prevent="submitForm">
            <div class="card-body text-justify" title="Compartilhe o link do seu navegador para convidar outras pessoas.">
              <h2 class="card-title text-3xl font-semibold mb-6">Intercom - Comunicador com grupos de áudio e retorno.</h2>
   
                         <div class="mb-4">
                <label class="block text-gray-500 font-bold mb-2" for="inline-full-name">Insira o nome da sala</label>
                <div class="flex">
                  <input class="input input-bordered input-success w-full max-w-xs" style="background-color: black !important; color: white !important;" id="inline-full-name" type="text" v-model="roomName" pattern="^[a-zA-Z0-9_]+$" required />
                  <button class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" type="button" @click="generateRandomName">Gerar Sala</button>
                </div>
               </div>
              <div class="card-actions justify-end">
                <button class="btn btn-outline btn-accent btn-block rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" type="submit">Entrar</button>
       
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="flex iframe-container mt-12" v-if="showIframe">
        <div class="iframe-left flex-none"></div>
        <div class="iframe-right flex-grow">
          <div
            class="dropdown dropdown-hover floating-button"
            style="display: none !important"
          >
            <button
              class="btn btn-outline btn-accent normal-case text-xl ml-2 mb-2 dropdown-toggle"
              type="button"
            >
              Copiar URL da Sala
            </button>
            <ul
              class="shadow menu dropdown-content rounded-box w-52 dropdown-submenu"
            >
              <li>
                <a href="#" @click.prevent="copyRoomUrl1">
                  Copiar URL da Sala 1
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="copyRoomUrl2">
                  Copiar URL da Sala 2
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="copyRoomUrl3">
                  Copiar URL da Sala 3
                </a>
              </li>
            </ul>
          </div>

       <iframe :src="iframeSrc" allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"></iframe>
           </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../components/NavbarComponent.vue";
import FloatingLock from "../components/FloatingLock.vue";

const adjectives = [
  "amarelo",
  "azul",
  "branco",
  "cinza",
  "navve",
  "feliz",
  "frio",
  "quente",
  "leve",
  "pesado",
  "luz",
  "verde",
];
const nouns = [
  "abacaxi",
  "amor",
  "casa",
  "cavalo",
  "comida",
  "dinheiro",
  "floresta",
  "futebol",
  "lua",
  "mar",
  "musica",
  "sol",
];

export default {
  components: {
    NavbarComponent,
    FloatingLock,
  },
  data() {
    return {
      showIframe: false,
      showNavbar: true,
      roomName: "",
      iframeSrc: "",
    };
  },
  created() {
    this.checkRoomParam();
  },
  methods: {
    checkRoomParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const roomParam = urlParams.get("room");
      if (roomParam) {
        this.roomName = roomParam;
        this.submitForm();
      }
    },
    generateRandomName() {
      const adjective =
        adjectives[Math.floor(Math.random() * adjectives.length)];
      const noun = nouns[Math.floor(Math.random() * nouns.length)];
      const randomDigits = Math.floor(Math.random() * 100)
        .toString()
        .padStart(2, "0");
      const randomName = `${adjective}${noun}${randomDigits}`.toLowerCase();
      this.roomName = randomName;
    },
    submitForm() {
      const baseURL = `/alpha/Audio.html?scene=0&layout&remote&room=${this.roomName}&sl&maxbandwidth`;
      const extraParams = window.location.search
        .substr(1)
        .split("&")
        .filter((param) => !param.startsWith("room="))
        .join("&");
      this.iframeSrc = extraParams ? `${baseURL}&${extraParams}` : baseURL;
      setTimeout(() => {
        this.showIframe = true;
        this.showNavbar = false;
      }, 0);
      history.pushState({}, null, `/intercom/?room=${this.roomName}`); //aqui se altera o link que vai para o navegador
    },
    copyRoomUrl1() {
      const url = `${window.location.origin}/alpha/?room=${this.roomName}&scene=1&layout&remote`;
      this.copyUrlToClipboard(url);
    },

    copyRoomUrl2() {
      const url = `${window.location.origin}/alpha/?scene=0&layout&remote&room=${this.roomName}`;

      this.copyUrlToClipboard(url);
    },

    copyRoomUrl3() {
      const url = `${window.location.origin}/alpha/?room=${this.roomName}&scene=3&layout&remote`;
      this.copyUrlToClipboard(url);
    },

    copyUrlToClipboard(url) {
      navigator.clipboard.writeText(url).then(() => {
        alert("URL da Sala copiada para a área de transferência.");
      });
    },
  },
};
</script>

<style scoped>
html {
  background-color: #292f3b !important;
}
body {
  margin: 0;
}
.floating-button {
  position: fixed;
  top: 6.4rem;
  right: 3.4rem;
  z-index: 1000;
  zoom: 0.6;
}

.dropdown-submenu {
  background-color: #2a2a2a; /* Altere para a cor desejada */
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("");
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}
input {
  width: 100%;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  width: 100vw;
}
.iframe-left {
  flex: none;
  padding-left: 0px;
}
.iframe-right {
  flex-grow: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
