<template>
  
  <div class="iframe-wrapper">
    <div class="iframe-container">
      <div class="iframe-left">
        <iframe
          :src="iframeSrc"
          allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
        ></iframe>
      </div>
      <p class="url-display">{{ iframeSrc }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iframeSrc: '',
    };
  },
  created() {
    this.setIframeSrc();
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleIframeMessage);
  },
  methods: {
    setIframeSrc() {
      let queryString = window.location.search;
      if (queryString) {
        this.iframeSrc = `/alpha/index.html${queryString}&videocontrols&color=FFFF0`;
      } else {
        this.iframeSrc = `/alpha/?&videocontrols`;
      }
    },
    handleIframeMessage(event) {
     
      if (
        event.origin === 'https://navve.studio' &&
        event.data &&
        event.data.push
      ) {
        let newQueryString = '?push=' + encodeURIComponent(event.data.push);
        history.pushState({}, null, window.location.pathname + newQueryString);
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: black !important;
}
.iframe-wrapper {
  background-color: black !important;
  height: 100vh;
  width: 100vw;
}
.iframe-container {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  width: 100vw;
  background-color: black !important;
}
.iframe-left {
  flex: 1;
  background: url('') center center no-repeat !important;
  background-size: fit !important;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.url-display {
  padding: 10px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  word-wrap: break-word;
  display: none !important;
}
@media (max-width: 768px) {
  .iframe-container {
    height: 94vh !important;
  }
}
</style>
