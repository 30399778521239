<template><NavbarComponent />
  <div style="background-color: black;">
    

    <div class="background-image" v-show="!showIframe"></div>

    <div
      id="card"
      class="flex items-center justify-center min-h-screen px-4"
      v-if="!showIframe"
    >
      <div class="card-container centered">
        <br /><br /><br />
      
        <br /><br />
        <form
          style="max-width: 400px"
          class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl"
          @submit.prevent="submitForm"
        >
          <div
            class="card-body text-justify"
            title="Compartilhe o link do seu navegador para convidar outras pessoas."
          >
            <h2 class="card-title text-3xl font-semibold mb-6">
              Crie ou entre em uma sala de vídeo chamada!
            </h2>

            <div class="mb-4">
              <label
                class="block text-gray-500 font-bold mb-1"
                for="inline-full-name"
              >
                Insira o nome da sala </label
              ><br /><br />
              <div class="flex">
                <input
                  class="input input-bordered input-success w-full max-w-xs"
                  style="
                    background-color: black !important;
                    color: white !important;
                  "
                  id="inline-full-name"
                  type="text"
                  v-model="roomName"
                  pattern="^[a-zA-Z0-9_]+$"
                  required
                />
                <br /><br />

                <button
                  class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2"
                  type="button"
                  @click="generateRandomName"
                >
                  Gerar Sala
                </button>
              </div>
            </div>
            <div class="card-actions justify-end">
              <button
                class="btn btn-outline btn-accent btn-block rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2"
                type="submit"
              >
                Entrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="iframe-container mt-12" v-if="showIframe">
      <div class="iframe-left">
        <iframe
          :src="iframeSrc"
          allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarComponent from '../components/NavbarComponent.vue';

const adjectives = [
  'amarelo',
  'azul',
  'branco',
  'cinza',
  'navve',
  'feliz',
  'frio',
  'quente',
  'leve',
  'pesado',
  'luz',
  'verde',
];
const nouns = [
  'abacaxi',
  'amor',
  'casa',
  'cavalo',
  'comida',
  'corrida',
  'floresta',
  'futebol',
  'lua',
  'mar',
  'musica',
  'sol',
];

export default {
  components: {
    NavbarComponent,
  },
  data() {
    return {
      showIframe: false,
      roomName: '',
      iframeSrc: '',
    };
  },
  created() {
    this.checkRoomParam();
  },
  methods: {
    checkRoomParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const roomParam = urlParams.get('room');
      if (roomParam) {
        this.roomName = roomParam;
        this.submitForm();

        const copyButton = document.createElement('button');
        copyButton.innerHTML = 'Copiar URL da Sala';
        copyButton.className =
          'btn btn-outline btn-accent normal-case text-xl ml-2';
        copyButton.onclick = () => {
          const url = `${window.location.origin}/?room=${this.roomName}&hidemenu&whippush=https://simple.navve.studio/api/whip&whippushtoken=${this.roomName}`;
          navigator.clipboard.writeText(url).then(() => {
            alert('URL da Sala copiada para a área de transferência.');
          });
        };

        const navbar = document.querySelector('.dropdown');
        if (navbar) {
          navbar.appendChild(copyButton);
        }
      }
    },
    generateRandomName() {
      const adjective =
        adjectives[Math.floor(Math.random() * adjectives.length)];
      const noun = nouns[Math.floor(Math.random() * nouns.length)];
      const randomDigits = Math.floor(Math.random() * 100)
        .toString()
        .padStart(2, '0');
      const randomName = `${adjective}${noun}${randomDigits}`.toLowerCase();
      this.roomName = randomName;
    },
    submitForm() {
      const baseURL = `/engine/`;
      const systemParams = `?whippush=https://simple.navve.studio/api/whip&whippushtoken=navve&room=${this.roomName}&remote&effects&sl&label&hidetranslate`;
      const userParams = window.location.search
        .substr(1)
        .split('&')
        .filter((param) => !param.startsWith('room='))
        .join('&');

      this.iframeSrc = userParams
        ? `${baseURL}${systemParams}&${userParams}`
        : `${baseURL}${systemParams}`;

      setTimeout(() => {
        this.showIframe = true;
      }, 0);

      // Atualiza a URL do navegador para incluir apenas os parâmetros fornecidos pelo usuário
      history.pushState(
        {},
        null,
        userParams
          ? `/?room=${this.roomName}&${userParams}`
          : `/?room=${this.roomName}`,
      );
    },
  },
};
</script>
<style scoped>
html, body {
 
  margin: 0;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}
input {
  width: 100%;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh; /* Você pode precisar ajustar este valor */
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 0px); /* Supondo que a altura da navbar seja 60px */
  width: 100vw;
  padding-top: 60px; /* Espaço para a navbar */
}
.iframe-left {
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Adicione media queries para dispositivos móveis */
@media (max-width: 768px) {
  .card-container {
    margin-top: 20vh; /* Ajuste conforme necessário */
  }
  .iframe-container {
    height: calc(100vh ); /* Supondo que a altura da navbar em dispositivos móveis seja 80px */
    padding-top: 80px; /* Espaço para a navbar em dispositivos móveis */
  }
}
</style>