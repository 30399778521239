<template>
  <div class="container">
    <NavbarComponent id="navbar" />
    <div class="landing-page">
      <div class="feature-box2 min-h-screen bg-primary-focus">
        <div class="hero-content flex-col lg:flex-row-reverse">
          <div>
            <h1 class="text-5xl welcome-text text-accent" id="bemvindo">
              Bem-vindo ao navve.studio
            </h1>
            <div class="accordion">
              <div class="accordion-item">
                <h2
                  class="accordion-header text-black py-3 cursor-pointer"
                  @click="isAccordionOpen = !isAccordionOpen"
                  id="descubra"
                >
                  DESCUBRA MAIS
                </h2>
                <div class="accordion-panel" v-if="isAccordionOpen">
    <div :class="{ 'mobile-column': isMobile }" style="display: flex; column-gap: 20px">
   <div
                      style="
                        flex: 1;
                        font-size: 1.1em;
                        text-align: left;
                        padding: 20px;
                      "
                    >
                    <p class="py-6 text-black">
  Bem-vindo ao navve.studio, onde a inovação e qualidade se unem para proporcionar uma experiência única em transmissão de vídeo.
</p>
<br />
<p class="py-6 text-black">
  Descubra funcionalidades que revolucionam a interação com o vídeo, desde videochamadas de alta qualidade até a transmissão em tempo real.
</p>
</div>
<div
  style="
    flex: 1;
    font-size: 1.1em;
    text-align: left;
    padding: 20px;
  "
>
<p class="py-6 text-black">
  Seja você um profissional em busca de soluções integradas ou alguém explorando novas formas de comunicação visual, o navve.studio tem o que você precisa.
</p>
<br />
<p class="py-6 text-black">
  Estamos sempre inovando para oferecer recursos que colocam você um passo à frente no mundo da transmissão de vídeo. Junte-se a nós na nova era da comunicação visual.
</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


      <br />

      <br /><br />
      <div
        class="tutorial-section"
        @click="
          openExternalLink3(
            'https://www.youtube.com/channel/UCicG7RSiNvs9eHUWBWhTygw',
          )
        "
      >
        <div class="tutorial-box">
          <span class="tutorial-text">Tutoriais</span>
        </div>
      </div>

      <div class="features-grid">
        <div
          title="Experiência de Transmissão Superior com Whip"
          class="feature-box bg-neutral"
          v-if="showWhipConect"
          @click="$router.push('/whip-gen')"
        >
          <span class="feature-icon">🚀</span>
          <span class="feature-text">Whip Gen</span>
          <p class="feature-description">Envia sinais únicos de câmera.</p>
        </div>

        <div
          title="Transmissão Única e Fácil de Câmera e Tela"
          class="feature-box bg-neutral"
          @click="
            openExternalLink2(
              '/engine/?push&turn=navve;M28shauetSdf;turn:turn.sub.navve.studio:443&cbr',
            )
          "
        >
          <span class="feature-icon">📷</span>
          <span class="feature-text">Enviar camera</span>
          <p class="feature-description">
            Envia sinais únicos de câmera e tela.
          </p>
        </div>

        <div
          title="Envie seu sinal para seu sistema de transmissão. OBS ou vmix."
          class="feature-box bg-neutral"
          @click="$router.push('/diretor2')"
        >
          <span class="feature-icon">🎬</span>
          <span class="feature-text">Diretor</span>
          <p class="feature-description">Controle completo da video chamada.</p>
        </div>
        <div
          title="Conexões Fluidas com Vídeo Chamadas de Alta Qualidade. Ate 30 Usuarios"
          class="feature-box bg-neutral"
          v-if="showVideoChamada"
          @click="$router.push('/videochamada')"
        >
          <span class="feature-icon">🎥</span>
          <span class="feature-text">Video Chamadas</span>
          <p class="feature-description">
            Alta qualidade baixa
            latência.
          </p>
        </div>

        <div
          title="Guia Passo a Passo para Dominar o Whip"
          class="feature-box bg-neutral"
          @click="
            openExternalLink3(
              'https://docs.google.com/document/d/e/2PACX-1vTTiwhzlI256KtXGhpLtSR_jx02nyRIteyDmqtYpHcLIkxfKDsV1x54zPl8jxvTYWg8pp_lieLHq2ff/pub',
            )
          "
        >
          <span class="feature-icon">⚙️</span>
          <span class="feature-text">Manual Whip</span>
          <p class="feature-description">Como funciona? Use esse guia.</p>
        </div>

        <div
          title="Transmissão de Vídeo Whip Simplificada com OBS Studio"
          class="feature-box bg-neutral"
          @click="$router.push('/whip')"
        >
          <span class="feature-icon">📣</span>
          <span class="feature-text">Whip OBS STUDIO </span>
          <p class="feature-description">
            Sinal de video whip pelo OBS 30+
          </p>
        </div>

        <div
          title="Este sistema de reconhecimento de fala em tempo real surge como uma solução para permitir que qualquer conteúdo seja acessível a um custo baixo."
          class="feature-box bg-neutral"
          @click="$router.push('/caption')"
        >
          <span class="feature-icon">🎶</span>
          <span class="feature-text">Legendas</span>
          <p class="feature-description">
            Crie legendas ao vivo seu conteúdo acessível.
          </p>
        </div>

        <div
          title="Comunicação Integrada de Voz e Vídeo para Produções Profissionais"
          class="feature-box bg-neutral"
          v-if="showInterCom"
          @click="$router.push('/intercom')"
        >
          <span class="feature-icon">📞</span>
          <span class="feature-text">InterCom</span>
          <p class="feature-description">
            Comunicacao de voz e retorno de video para sua produção.
          </p>
        </div>
         <div
          title="Transmissão Única e Fácil de Arquivos de Video"
          class="feature-box bg-neutral"
          @click="
            openExternalLink2(
              '/engine/?push&turn=navve;M28shauetSdf;turn:turn.sub.navve.studio:443&cbr&fileshare',
            )
          "
        >
          <span class="feature-icon">🔗</span>
          <span class="feature-text">Arquivo de video</span>
          <p class="feature-description">
            Envia Arquivo de video para stream.
          </p>
        </div>
        <div
          title="Veja sua transmissao de Vídeo"
          class="feature-box bg-neutral"
          @click="openExternalLink2('https://navve.studio/alpha/view.html')"
        >
          <span class="feature-icon">▶️</span>
          <span class="feature-text">Ver video</span>
          <p class="feature-description">Veja sua transmissao de Vídeo</p>
        </div>

        <div
          class="feature-box"
          v-if="OffDisplay"
          @click="$router.push('/predef')"
        >
          <span class="feature-icon">📅</span>
          <span class="feature-text">Sala Predefinida</span>
        </div>
        <div
          class="feature-box"
          v-if="OffDisplay"
          @click="$router.push('/playout')"
        >
          <span class="feature-icon">▶️</span>
          <span class="feature-text">Player</span>
        </div>
        <div
          class="feature-box"
          v-if="OffDisplay"
          @click="$router.push('/blau')"
        >
          <span class="feature-icon">🔍</span>
          <span class="feature-text">Blau</span>
        </div>
        <div
          class="feature-box"
          v-if="OffDisplay"
          @click="$router.push('/mixer')"
        >
          <span class="feature-icon">🎶</span>
          <span class="feature-text">Mixer</span>
        </div>
        <div
          class="feature-box"
          v-if="OffDisplay"
          @click="$router.push('/viewlink')"
        >
          <span class="feature-icon">🔗</span>
          <span class="feature-text">ViewLink</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue';

export default {
  name: 'LandingPage',
  components: {
    NavbarComponent,
  },
  data() {
    return {
      isAccordionOpen: false,
      showVideoChamada: true,
      showWhipConect: true,
      showInterCom: true,
      showOffDisplay: false,
      isMobile: window.innerWidth <= 768,
    };
  },
  methods: {
    openExternalLink(url) {
      window.open(url, '_self');
    },
    openExternalLink2(url) {
      window.open(url, '_self');
    },
    openExternalLink3(url) {
      window.open(url, '_blank');
    },
    showComingSoonAlert() {
      alert('Em breve');
    },
    updateIsMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

.container{
   background: url('https://i.imgur.com/XPlv97e.jpeg') ;
  background-size: contain ;
    background-repeat: repeat;
    background-position: center; 
}
.text-black {
    font-family: 'Nunito', sans-serif !important;
}


.tutorial-section {
  display: flex;

  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* ou qualquer outro valor para ajustar a margem */
}

.tutorial-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('https://i.imgur.com/HamcVcJ.jpg') no-repeat center center;
  background-size: cover;
  width: calc(100% - 40px); /* Ajuste conforme necessário */
  height: 200px; /* Ajuste conforme necessário */
  border-radius: 15px;
  color: #ffffff; /* Cor do texto */
  cursor: pointer;
  transition: all 0.3s;
}

.tutorial-box:hover {
  background-color: #e5e7eb10;
  border: 3px solid #00d0ff; /* Adicionada espessura de 3px e o estilo 'solid' à borda */
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  opacity: 1;
}

.tutorial-text {
  font-size: 2rem; /* Ajuste conforme necessário */
  font-weight: bold; /* Ajuste conforme necessário */
}

#bemvindo {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;

  font-size: 2em;
  color: #00d0ff;
}
#descubra {
  font-weight: 200;
  text-decoration: underline;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 60px; /* considering the navbar's height */
}

#navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.landing-page {
  max-width: 1200px; /* max-width for desktop centralization */
  width: 100%;
  text-align: center;
  padding: 20px;
  color: #e5e7eb;
}

.welcome-text {
  font-size: 2rem;
  margin-bottom: 20px;
}

.brief-description {
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.feature-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1f1f1f;
  border: 3px solid #00d0ff10; /* Adicionada espessura de 3px e o estilo 'solid' à borda */
  color: rgb(228, 228, 228);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  opacity: 0.7;
  height: 200px; /* to make it square */
}
.feature-box2 {
  border-radius: 05px;
  background: linear-gradient(270deg, #111111, #1f1f1f, #1a1a1a);
    background-size: 200% 200%;
    -webkit-animation: AnimationName 30s ease infinite;
    -moz-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
.feature-box:hover {
  background-color: #e5e7eb10;
  border: 3px solid #00d0ff; /* Adicionada espessura de 3px e o estilo 'solid' à borda */
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #00d0ff;
  opacity: 1;
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.feature-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 0.9rem;
  position: absolute;
  bottom: 10px;
}
@media (max-width: 768px) {
  .accordion-panel div[style*="display: flex"] {
    flex-direction: column !important;
  }
}

</style>
