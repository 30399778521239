<template>
  <div class="dark">
    <NavbarComponent />
    <div class="iframe-container mt-12" v-if="showIframe">
      <div class="iframe-left">
        <iframe
          :src="'/alpha/mixer.html'"
          allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarComponent from "../components/NavbarComponent.vue";

export default {
  components: {
    NavbarComponent,
  },
  data() {
    return { showIframe: true, roomName: "" }; // Altere "sala_especifica" para o nome da sala desejada
  },
};
</script>
<style scoped>
body {
  margin: 0;
}
input {
  width: 100%;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh); /* altura total da janela menos a altura da navbar */
  width: 100vw;
  padding-top: 60px;
}
.iframe-left {
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
