<template>
  <div style="background-color: black;">
    <NavbarComponent />
    <div class="iframe-container mt-12">
      <iframe
        src="/legenda/index.html"
        class="w-full h-full border-none"
        allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
      ></iframe>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '../components/NavbarComponent.vue';

export default {
  components: {
    NavbarComponent,
  },
};
</script>

<style scoped>
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  margin-top:60px;
  height: calc(100vh - 60px); /* Ajuste conforme a altura real da sua Navbar */
  width: 100vw;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
@media (max-width: 768px) {
  .iframe-container {
    height: calc(100vh - 80px); /* Ajuste conforme a altura real da sua Navbar em dispositivos móveis */
  }
}
</style>
