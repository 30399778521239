import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './index.css'; // Se estiver usando Tailwind CSS
import 'daisyui/dist/full.css'; // Se estiver usando DaisyUI
import './registerServiceWorker';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGtag from "vue-gtag"; // Importe o plugin vue-gtag

library.add(faLock, faUnlock);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(VueGtag, {
  config: { id: 'G-P5GYZ28F9B' } // Configure o plugin com o seu ID do Google Analytics
});
app.mount('#app');