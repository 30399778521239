<template>
  <div class="dark">
    <NavbarComponent />
    <div
      id="card"
      class="flex items-center justify-center min-h-screen px-4"
      v-if="!showIframe"
    >
      <div class="card-container centered">
        <form
          style="max-width: 400px"
          class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl"
          @submit.prevent="submitForm"
        >
          <div class="card-body text-justify">
            <h2 class="card-title text-3xl font-semibold mb-6">
              Entrar na sala do diretor
            </h2>
            <div class="mb-4">
              <label
                class="block text-gray-500 font-bold mb-1"
                for="inline-full-name"
              >
                Insira o nome da sala
              </label>
              <input
                class="input input-bordered input-success w-full max-w-xs"
                id="inline-full-name"
                type="text"
                v-model="roomName"
                pattern="^[a-zA-Z0-9_]+$"
                required
              />
            </div>
            <div class="card-actions justify-end">
              <button
                class="btn btn-outline btn-accent btn-block rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500"
                type="submit"
              >
                Entrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="iframe-container mt-12" v-if="showIframe">
      <div class="iframe-left">
        <iframe
          :src="iframeSrc"
          allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarComponent from "../components/NavbarComponent.vue";

export default {
  components: {
    NavbarComponent,
  },
  data() {
    return { showIframe: false, roomName: "", iframeSrc: "" };
  },
  created() {
    this.checkRoomParam();
  },
  methods: {
    checkRoomParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const roomParam = urlParams.get("room");

      if (roomParam) {
        this.roomName = roomParam;
        this.submitForm();
      }
    },
    submitForm() {
      const baseURL = `/alpha/index.html?director=${this.roomName}&sstype=3&hidetranslate&cleandirector&sl&hidemenu&label=diretor:`;
      const extraParams = window.location.search
        .substr(1)
        .split("&")
        .filter((param) => !param.startsWith("room="))
        .join("&");
      this.iframeSrc = extraParams ? `${baseURL}&${extraParams}` : baseURL;
      this.showIframe = true;
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
}
input {
  width: 100%;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  width: 100vw;
  padding-top: 60px;
}
.iframe-left {
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
