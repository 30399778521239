<template>
  <nav class="fixed-navbar">
    <div class="container items-center">
      <div class="dropdown">
        <button class="" @click="toggleDropdown">
          <img
            src="/img/2.png"
            alt="studio"
            style="width: 120px; height: 55px"
            id="logonav"
            class="btn btn-outline btn-neutral normal-case text-lg btn-largo"
          />
        </button>
        <ul
          class="shadow menu dropdown-content rounded-box w-64 dropdown-largo"
          v-show="isDropdownOpen"
        >
          <li class="menu-item">
            <a href="/land" title="Volte para a página inicial">
              <span class="emoji">🏠</span> Home
            </a>
          </li>
          <hr class="menu-divider" />
          <li class="menu-item">
            <a
              href="/caption"
              title="Crie legendas ao vivo tornando seu conteúdo acessível"
            >
              <span class="emoji">🎶</span> Legendas
              <small style="color: red">novo</small>
            </a>
          </li>
          <li class="menu-item">
            <a
              href="/trans/"
              title="Crie legendas ao vivo Tradução simultânea"
            >
              <span class="emoji">🤯</span> Tradução
              <small style="color: green">Beta</small>
            </a>
          </li>
          <hr class="menu-divider" />
          <li class="menu-item">
            <a
              target="_self"
              :href="`/engine/?whippush=https://simple.navve.studio/api/whip&whippushtoken=${whippushtoken}&push=`"
              title="Envia sinais únicos de câmera e tela"
            >
              <span class="emoji">📷</span> Transmitir
            </a>
          </li>
          <li class="menu-item">
            <a
              target="_self"
              href="/videochamada"
              title="Realize videochamadas de alta qualidade com baixa latência"
            >
              <span class="emoji">😎</span> Vídeo Chamada
            </a>
          </li>
          <li class="menu-item">
            <a href="/diretor2" title="Controle completo da videochamada">
              <span class="emoji">🎬</span> Diretor
            </a>
          </li>
          <hr class="menu-divider" />
          <li class="menu-item">
            <a href="/whip" title="Sinal de vídeo whip pelo OBS 30+">
              <span class="emoji">🎥</span> Whip OBS
              <small style="color: red">novo</small>
            </a>
          </li>
          <li class="menu-item">
            <a href="/whip-gen" title="Envia sinais únicos de câmera">
              <span class="emoji">🔗</span> Whip Gen
              <small style="color: red">novo</small>
            </a>
          </li>
          <hr class="menu-divider" />
          <li class="menu-item">
            <a
              href="/intercom"
              title="Comunicação de voz e retorno de vídeo para sua produção"
            >
              <span class="emoji">📞</span> InterCom
            </a>
          </li>
          <li>
            <button
              style="display: none; font-size: 0.875rem !important"
              class="btn btn-outline btn-accent normal-case text-lg"
              @click="copyToClipboard"
            >
              Copiar link
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
#logonav:hover {
  background-color: #00aeff;
}
.fixed-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 56px;
  display: flex;
  align-items: left;
  justify-content: left;
  background-color: #1a1a1a; /* Fundo escuro para a barra de navegação */
  padding-bottom: env(safe-area-inset-bottom); /* Ajuste para iOS */
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn-largo {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #2a2a2a; /* Fundo escuro para o botão */
  color: #fff; /* Texto branco para o botão */
  border: 1px solid #2e2e2e; /* Borda sutil para o botão */
  margin-right: 3rem;
  margin-left: 3rem;
  border-radius: 5px;
}
.dropdown {
  position: relative;
}
.menu {
  background-color: #181818; /* Fundo escuro para o menu */
}
.menu li {
  list-style-type: none;
  padding: 0.2rem 1rem;
}
.menu li a {
  text-decoration: none;
  color: #fff; /* Texto branco para os itens do menu */
  display: flex;
  align-items: center;
  font-size: 0.875rem; /* Tamanho de fonte menor para os itens do menu */
}
.menu li a .emoji {
  margin-right: 1rem;
}
.menu li a:hover {
}
.menu li:hover {
  border: 1px solid #00aeff;
  border-radius: 10px;
  background-color: black;
}
.menu hr {
  margin: 0;
  border-top: 1px solid #444; /* Linhas horizontais mais sutis */
}
</style>

<script>
export default {
  data() {
    return {
      isDropdownOpen: false,
      showCopyButton: false,
      whippushtoken: '', // Adicionado para armazenar o token gerado
    };
  },
  created() {
    this.checkRoomParam();
    window.addEventListener('popstate', this.checkRoomParam); // adiciona o evento popstate para detectar mudanças na URL
  },
  mounted() {
    this.generateRandomToken(); // Gerar o token quando o componente é montado
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.checkRoomParam); // remove o evento popstate
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    checkRoomParam() {
      const urlParams = new URLSearchParams(window.location.search);
      this.showCopyButton = urlParams.has('room');
    },
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Link copiado para a área de transferência!');
    },
    generateRandomToken(length = 20) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      this.whippushtoken = result; // Armazenando o token gerado
    },
  },
};
</script>
