<!-- FloatingLock.vue -->
<template>
  <div class="floating-lock" @click="toggleLock">
    <font-awesome-icon
      :icon="lockActive ? 'lock' : 'unlock'"
      size="2x"
      alt="Travar toques na tela"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      lockActive: false,
      wakeLock: null,
    };
  },
  methods: {
    async toggleLock() {
      this.lockActive = !this.lockActive;
      const contentContainer = document.querySelector(".content-container");
      contentContainer.style.pointerEvents = this.lockActive ? "none" : "auto";

      if (this.lockActive) {
        try {
          if ("wakeLock" in navigator) {
            this.wakeLock = await navigator.wakeLock.request("screen");
          }
        } catch (err) {
          console.error(`Falha ao solicitar o WakeLock: ${err.message}`);
        }
      } else {
        if (this.wakeLock) {
          this.wakeLock.release();
          this.wakeLock = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.floating-lock {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  cursor: pointer;
}
</style>
